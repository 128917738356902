@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/mixins';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.drop-down-button {
  position: relative;
  & > button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    height: $header-height;
    &:active {
      color: inherit;
    }
    .icon {
      margin-right: $spacing-medium;
    }
    .button-text {
      margin-right: $spacing-small;
      color: $grey-60;
    }
    .button-arrow {
      margin-right: 0;
      color: $affordance;
    }
    &.disabled {
      cursor: default;
    }
  }

  .header-box {
    top: 5.2rem;
    right: -$spacing-small;
  }
}
