@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/mixins';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';
@import 'mypages-library/dist/styles/z-index';

.ie11-browser-notice-overlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba($black, 0.6);
  width: 100%;
  height: 100%;
  z-index: $browser-notice-overlay;
}

.ie11-browser-notice {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: $spacing-medium;
  height: 280px;
  width: 512px;
  border-radius: $border-radius-modal;
  background-color: $white;
  box-shadow: $box-shadow;
  z-index: $browser-notice;
  color: $grey-60;
  font-size: $font-size-base;
  line-height: $spacing-large;

  .ie11-browser-notice-title {
    font-size: $font-size-title;
    line-height: $spacing-xlarge;
    color: $black;
    margin-bottom: $spacing-medium;
  }

  .ie11-browser-notice-content {
    flex: 1;
  }

  .ie11-browser-notice-icons {
    margin-top: $spacing-large;

    a {
      display: inline-block;
      width: 24%;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }
  }
}

.ie-browser-notice-overlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: $white;
  opacity: $high-opacity;
  width: 100%;
  height: 100%;
  z-index: $browser-notice-overlay;
}

.ie-browser-notice {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border-top: 5px solid $tangerine;
  border-bottom: 5px solid $tangerine;
  background-color: $white;
  color: $black;
  z-index: $browser-notice;
  margin-top: 20%;
  width: 100%;
}

.ie-browser-notice--box {
  margin: 0 15%;
  padding: $spacing-small $spacing-xxl;

  .ie-browser-notice--image {
    display: inline;
    position: absolute;
    left: 15%;
    top: 0;
  }

  .ie-browser-notice--text {
    display: block;
  }
}
