@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

$wrapper-max-width: 62rem;

.login-wrapper {
  max-width: $wrapper-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacing-medium;

  .login-content {
    width: 29.6rem;
    margin: 0 $spacing-medium;

    .token-field {
      margin-bottom: 0;
    }
    .missing-token-link {
      margin-top: $spacing-medium;
      display: block;
      text-align: center;
      width: 100%;
      color: $affordance;
      cursor: pointer;
      font-family: $font-family-medium;
    }

    .go-back-link,
    .phone-number {
      display: block;
      text-align: center;
      width: 100%;
      color: $affordance;
      cursor: pointer;
      font-family: $font-family-medium;
    }

    .error-message {
      font-size: $font-size-small;
      color: $error;
      line-height: $spacing-large;
      margin: 0 4px $spacing-small 4px;
    }

    .submit-button {
      margin-top: $spacing-medium;
      width: 100%;
    }

    .validate-button {
      margin-top: $spacing-large;
      width: 100%;
    }

    .title {
      margin: $spacing-medium 0;
      text-align: center;
    }

    .call-text {
      margin-top: $spacing-large;
      text-align: center;
    }

    .text-sub {
      margin-top: $spacing-medium;
      text-align: center;
    }

    .text {
      margin: $spacing-medium 0;
      text-align: center;
      width: 100%;
      color: $font-color-light;
    }

    .title-no-bottom-margin {
      margin-top: $spacing-medium;
      text-align: center;
    }

    .text-no-top-margin {
      margin-bottom: $spacing-medium;
      text-align: center;
      width: 100%;
      color: $font-color-light;
    }

    .divider {
      border: 0;
      border-top: 1px solid $grey-15;
      margin: $spacing-medium 0;
    }
  }
}
