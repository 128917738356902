@import 'mypages-library/dist/styles/mixins';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-large;
  margin-bottom: $spacing-xxl;
  .cookie-policy-title {
    font-family: $font-family-medium;
  }

  .cookie-policy-wrapper {
    max-width: 88rem;
    color: $font-color-default;
  }
  @include small-screen {
    padding: 0 $spacing-medium;
  }
}
