@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.language-menu {
  .language {
    display: flex;
    align-items: center;
    .selected {
      width: 0.8rem;
      height: 0.8rem;
      color: $affordance;
      margin-left: auto;
    }
  }
  .language-description {
    border-top: 1px solid $border-color;
    padding: $spacing-small $spacing-medium;
    cursor: default;
    font-size: $font-size-small;
    color: $grey-60;
    &:hover {
      background: white;
    }
  }
}
