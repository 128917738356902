@import 'mypages-library/dist/styles/animations';
@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/mixins';
@import 'mypages-library/dist/styles/typography';

.header {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: $font-size-title;
  font-size: $font-size-title;
  color: $font-color-light;
  text-align: center;
  padding: $spacing-small 0;
}

.text {
  text-align: left;
  padding: $spacing-medium $spacing-medium $spacing-xlarge;
  font-size: $font-size-title;
  font-family: $font-family-book;
}

.content {
  margin-bottom: $spacing-medium;
}
.popoverContainer {
  margin-left: auto;
}

.popoverContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  .contact-icon {
    margin-left: $spacing-small;
  }

  .popover {
    bottom: 30px;
    left: -210px;
    margin-left: $spacing-small;

    .infoText {
      padding: $spacing-small;
    }
  }
}
