@import 'mypages-library/dist/styles/colors.scss';
@import 'mypages-library/dist/styles/mixins.scss';
@import 'mypages-library/dist/styles/sizes.scss';
@import 'mypages-library/dist/styles/typography.scss';

.panelWrapper {
  position: fixed;
  top: 0;
  z-index: 15000;
  background-color: $white;
  width: 43.2rem;
  right: -43.2rem;
  height: 100%;
  box-shadow: -1px 0 4px 0 rgba(41, 41, 41, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 350ms ease;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

  &.panel-wrapper-entered {
    right: 0;
  }

  @include small-screen {
    width: 100vw;
  }
}
