@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

$wrapper-max-width: 62rem;

.login-wrapper {
  max-width: $wrapper-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacing-medium;

  .login-content {
    width: 29.6rem;
    margin: 0 $spacing-medium;

    .logo {
      margin: 4.8rem auto 3.2rem auto;
    }

    .login-field {
      margin-bottom: $spacing-xlarge;
    }

    .forgot-pswd-link {
      display: block;
      margin-bottom: $spacing-medium;
      text-align: center;
      width: 100%;
    }

    .error-message {
      font-size: $font-size-small;
      color: $error;
      line-height: $spacing-large;
      margin: 0 4px $spacing-small 4px;
    }

    .login-button {
      width: 100%;
      margin-bottom: $spacing-large;
    }
    .sign-up-text {
      margin: $spacing-medium 0;
      text-align: center;
      width: 100%;
    }
  }
}
