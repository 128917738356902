@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';
@import 'mypages-library/dist/styles/z-index';

.popup-overlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba($black, $medium-opacity);
  width: 100%;
  height: 100%;
  z-index: $browser-notice-overlay;
}

.popup-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $modal;

  .popup-box {
    background-color: $white;
    z-index: $modal;
    padding: $spacing-medium $spacing-large;
    max-width: 50vw;
    border-radius: $border-radius-modal;

    @media screen and (max-width: $medium-screen) {
      max-width: 90vw;
    }

    .title {
      font-size: $font-size-title;
      font-weight: 700;
      margin-bottom: $spacing-large;
    }

    .description {
      color: $grey-60;
      font-size: $font-size-base;
      margin-bottom: $spacing-medium;
    }

    .call-to-action {
      display: block;
      text-align: right;
      color: $affordance;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      cursor: pointer;
    }

    .error-message {
      font-size: $font-size-small;
      color: $error;
      line-height: $spacing-large;
      margin: 0 4px $spacing-small 4px;
    }
  }
}
