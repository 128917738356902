@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.elpWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  flex-direction: row;
  .error-message {
    font-size: $font-size-small;
    color: $error;
    line-height: $spacing-large;
    margin: 0 4px $spacing-small 4px;
    margin-top: 100%;
  }
}
