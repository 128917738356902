@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 25em;
  height: 15em;
  background-color: $white;

  .title {
    width: 100%;
    padding: 0.5em;
    height: 3em;
    text-align: center;
    border-bottom: 1px solid $grey-10;
  }
  .scroll {
    flex: 1;
    overflow-y: auto;
    max-height: 9em;
  }
  .text {
    padding: $spacing-medium;
    flex: 1;
  }
  .question {
    padding: 0 $spacing-medium $spacing-medium;
    flex: 1;
  }
  .btnContainer {
    display: flex;
  }
  .btnConfirm {
    width: 50%;
    height: 3em;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    color: $red;
    border-top: 1px solid $grey-10;
    border-right: 1px solid $grey-10;
    background-color: $white;
    text-align: center;
  }
  .btnCancel {
    width: 50%;
    height: 3em;
    bottom: 0;
    right: 0;
    overflow: hidden;
    position: absolute;
    border-top: 1px solid $grey-10;
    background-color: $white;
    text-align: center;
  }
}
