@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';

.drop-down-box {
  position: absolute;
  bottom: 6rem;
  height: 0;
  max-height: 600px;
  min-width: 232px;
  overflow-y: auto;
  z-index: 1;
  opacity: 0;
  background: $white;
  box-shadow: 0 6px 8px 0 rgba(41, 41, 41, 0.2);
  border-radius: $border-radius;
  border: 0;
  transition: opacity 350ms cubic-bezier(0, 0, 0.58, 1);

  &.expanded {
    height: auto;
    opacity: 1;
    border: 1px solid $border-color;
  }
  ul {
    list-style: none;
    li {
      min-height: 4rem;
      box-sizing: border-box;

      &.box-title {
        border: 0;
        padding: 0 $spacing-medium;
        height: 4rem;
        line-height: 4rem;
        border-bottom: 1px solid $divider-color;
      }
      &:last-child {
        border: 0;
      }
      &:not(.box-title):hover {
        background-color: $item-hover-bg;
      }
      button {
        background: none;
      }
      > button,
      > a {
        width: 100%;
        min-height: inherit;
        text-align: left;
        padding: 0 $spacing-medium;
      }
      > a {
        display: flex;
        align-items: center;
      }
    }
  }
}
