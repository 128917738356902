@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

$wrapper-max-width: 62rem;

.login-wrapper {
  max-width: $wrapper-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacing-medium;

  .login-content {
    width: 29.6rem;
    margin: 0 $spacing-medium;

    .illustration {
      margin: 4.8rem auto 3.2rem auto;
    }

    .login-button {
      width: 100%;
    }

    .text {
      margin: $spacing-medium 0;
      text-align: left;
      width: 100%;
      color: $font-color-light;
    }

    .title {
      margin-bottom: $spacing-medium;
    }
  }
}
