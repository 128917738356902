@import 'mypages-library/dist/styles/animations';
@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/mixins';
@import 'mypages-library/dist/styles/typography';

$input-height: 4rem;
$border-radius: 3px 3px 0 0;

.input-container {
  margin-bottom: $spacing-medium;

  .error-message {
    font-size: $font-size-small;
    color: $error;
  }
  .wrapper {
    position: relative;
    height: $input-height;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    border-bottom: none;
    background: $white;
    display: block;

    .input {
      border-radius: $border-radius;
      border: none;
      border-bottom: 2px solid $grey-30;
      background: $grey-10;
      padding: $spacing-medium $spacing-small 0 $spacing-small;
      transition: $fast-speed ease all;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      &.disabled {
        background: rgba($background, 0.7);
      }

      &:focus {
        border-bottom-color: $black;
      }
    }

    .label {
      @include ellipsis();
      color: $grey-60;
      position: absolute;
      pointer-events: none;
      left: $spacing-small;
      right: $spacing-small;
      bottom: 5px;
      transition: $fast-speed ease all;
      &.label-for-password {
        right: 3.2rem;
      }
    }

    .input-field-icon {
      height: $input-height;
      width: $input-height;
      position: absolute;
      right: 0;
      text-align: center;
      vertical-align: middle;
      color: $black;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        color: $grey-60;
      }
    }

    &.with-icon {
      .input {
        padding-right: calc(#{$input-height} + 5px);
      }
    }

    &.error {
      .input {
        border-color: $error;
      }

      .label {
        color: $error;
      }
    }
  }
}
.assistive {
  margin-top: 0;
  font-size: $font-size-small;
  color: $grey-60;
}
.wrapper.has-value .label,
.input:focus ~ .label {
  bottom: calc(100%);
  transform: translateY(100%);
  font-size: 1rem;
}
