@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.centered {
  max-width: 100%;
  display: flex;
  justify-content: center;

  .container {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .infoContainer {
      display: flex;
      flex-direction: column;
      padding: $spacing-medium;

      .infoHeader {
        font-size: $font-size-title;
      }

      .infoText {
        font-size: $font-size-base;
        color: $font-color-light;
      }
    }
  }
  .loginBtn {
    margin-top: $spacing-medium;
    width: 90%;
  }
}
