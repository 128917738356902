@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

$wrapper-max-width: 62rem;

.cookie-banner {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: $black;
  color: $white;
  align-items: center;
  padding: 16px 0;
  line-height: 24px;
  left: 0;

  .cookie-banner-content {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    .cookie-info-link {
      color: inherit;
      text-decoration: underline;
    }
  }
  .cookie-banner-close-icon {
    color: inherit;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    right: 16px;
  }

  @media screen and (max-width: 550px) {
    text-align: left;
    padding: 16px;
    .cookie-banner-content {
      margin-right: 32px;
    }
    .cookie-banner-close-icon {
      top: 16px;
      bottom: auto;
    }
  }
}
