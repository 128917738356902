@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.link-text {
  margin: 1rem;
  background: transparent;
  color: $red;
}

.termsContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  width: 100%;
  align-items: center;
}
.textContainer {
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
  flex: 1;
  overflow-y: auto;
}

.optionButtons {
  border-top: 1px solid $divider-color;
  width: 100%;
  background-color: $white;
  position: sticky;
  bottom: 0;
  border-bottom: 1px solid $border-color;

  .optionButton {
    width: 100%;
    height: 6.4rem;
    border-radius: 0;
    &:first-child {
      border-right: 1px solid $divider-color;
      border-left: 1px solid $divider-color;
    }
  }
}
