@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/mixins';
@import 'mypages-library/dist/styles/sizes';

.validationContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  &:focus-within {
    .requirementsContainer {
      display: block;
    }
    .errorContainer {
      display: none;
    }
  }
  .errorContainer {
    color: $error;
    display: flex;
    gap: 1rem;
    .errorIcon {
      margin: 0.5rem 0;
    }
    .validationError {
      list-style: none;
      list-style-position: inside;
      font-size: 1.2rem;
    }
  }
  .requirementsContainer:before {
    @include medium-screen-and-larger {
      left: -6px;
      top: 10%;
      height: 10px;
      width: 10px;
      border: 1px solid #dcddde;
      box-shadow: -2px 2px 0px 0px #ffffff;
      border-right: none;
      border-top: none;
      content: ' ';
      background: white;
      position: absolute;
      pointer-events: none;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .requirementsContainer {
    display: none;
    color: #7f7f7f;
    @include medium-screen-and-larger {
      border: 1px solid #dcddde;
      position: absolute;
      right: -270px;
      border-radius: 3px;
      padding: 10px;
      width: 249px;
      height: 168px;
      top: -1px;
      box-shadow: 0 9px 10px 0 rgba(41, 41, 41, 0.15);
    }
    @include small-screen {
      position: relative;
    }
  }
  .requirementsList {
    list-style: none;
    list-style-position: inside;
    padding: 0;
    margin: 0 $spacing-small $spacing-small;
  }

  .row {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 0.4rem;
    }
  }
}
