@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

.apps {
  display: flex;

  .app-store-image {
    height: 31px;
    margin-right: $spacing-medium;
  }

  .google-play-image {
    height: 31px;
  }
}
