@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

$wrapper-max-width: 62rem;

.login-wrapper {
  max-width: $wrapper-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacing-medium;

  .login-content {
    width: 29.6rem;
    margin: 0 $spacing-medium;

    .logo {
      margin: 4.8rem auto 3.2rem auto;
    }

    .login-field {
      margin-bottom: $spacing-xlarge;
    }
    .forgot-pswd-link,
    .go-back-link {
      display: block;
      margin-bottom: $spacing-medium;
      text-align: center;
      width: 100%;
    }

    .error-message {
      font-size: $font-size-small;
      color: $error;
      line-height: $spacing-large;
      margin: 0 4px $spacing-small 4px;
    }

    .login-button {
      width: 100%;
      margin-bottom: $spacing-large;
    }

    .sign-up-text {
      margin: $spacing-medium 0;
      text-align: center;
      width: 100%;
    }

    .divider {
      border: 0;
      border-top: 1px solid $grey-15;
      margin-bottom: $spacing-medium;
    }

    .download-title {
      margin-bottom: $spacing-small;
      text-align: center;
    }

    .apps {
      display: flex;
      margin-bottom: 10rem;

      .app-store-image {
        height: 31px;
        margin-right: $spacing-medium;
      }

      .google-play-image {
        height: 31px;
      }
    }
  }
  .links {
    border-top: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .webshop-icon {
      vertical-align: text-bottom;
      margin-right: calc($spacing-small / 2);
      color: $grey-30;
    }
  }
}

.cookie-banner {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: $black;
  color: $white;
  align-items: center;
  padding: 16px 0;
  line-height: 24px;
  left: 0;

  .cookie-banner-content {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    .cookie-info-link {
      color: inherit;
      text-decoration: underline;
    }
  }
  .cookie-banner-close-icon {
    color: inherit;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    right: 16px;
  }

  @media screen and (max-width: 550px) {
    text-align: left;
    padding: 16px;
    .cookie-banner-content {
      margin-right: 32px;
    }
    .cookie-banner-close-icon {
      top: 16px;
      bottom: auto;
    }
  }
}
