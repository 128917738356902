@import 'mypages-library/dist/styles/colors';
@import 'mypages-library/dist/styles/sizes';
@import 'mypages-library/dist/styles/typography';

$wrapper-max-width: 62rem;
.wrapper {
  max-width: $wrapper-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-medium;
  padding-bottom: $spacing-large;

  .content {
    width: 30.6rem;
    margin: 0 $spacing-medium;
    padding-bottom: $spacing-large;
    img {
      margin-top: 2rem;
    }

    .illustration {
      margin: 4.8rem auto 3.2rem auto;
    }

    .login-button {
      margin-top: $spacing-medium;
      width: 100%;
    }
    .cancel-button {
      margin-top: $spacing-small;
      margin-left: -$spacing-small;
    }

    .text {
      margin: $spacing-medium 0;
      text-align: left;
      width: 100%;
      color: $font-color-light;
    }

    .disabledInput {
      input {
        color: $font-color-light;
      }
    }

    .title {
      margin-bottom: $spacing-medium;
    }
    .agreement {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-medium;
    }
    .agreement-text {
      display: block;
      margin-left: $spacing-small;
      column-gap: 0.25rem;
    }

    .link-text {
      display: inline-block;
      background: transparent;
      color: $red;

      &.agreement-link {
        margin-left: 0.25rem;
      }
    }
    .privacy-text {
      margin-bottom: $spacing-medium;
    }
    .error {
      color: $error;
      margin-bottom: $spacing-small;
    }
    .create-account-error {
      color: $error;
      margin-top: $spacing-medium;
      margin-bottom: $spacing-medium;
    }
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extra-footer {
  margin-bottom: 10rem;
}
